body {
  background: #ffffff;
  font-family: 'Raleway', sans-serif;

}

.user_info {
  margin: 10px;
  float: right;
}


div.jumbotron{
  background-color: #F15A2B;
  color:#ffffff;
}

div.jumbotron h1{
  font-size: 3.5rem;
}
.panel-default {
  border-top-color: #F15A2B;
}
.navbar-static-top, .navbar-fixed-top {
  border-top: 2px solid #F15A2B;
}
nav {margin-bottom:20px}

footer {
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px;
  background-color: #f5f5f5;
  color:#101010;
  font-size:80%;
}
